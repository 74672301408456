<template>
  <a-page-header
    style="border: 1px solid rgb(235, 237, 240)"
    title="返回"
    @back="() => $router.go(-1)"
  />
  <a-row style="padding: 0 10px">
    <markdown-render
      class="markdown-render"
      :markdown-data="state.data"
    ></markdown-render>
  </a-row>
</template>

<script>
import commonApi from '@/api/common'
import MarkdownRender from '@/components/MarkdownRender'
import { reactive } from '@vue/reactivity'
export default {
  name: 'Detail',
  components: {
    MarkdownRender,
  },
  setup() {
    const state = reactive({
      data: '',
    })
    return {
      state,
    }
  },

  mounted() {
    this.getContent()
  },
  methods: {
    getContent() {
      commonApi.sooyue
        .getContent(this.$route.query.id, this.$route.query.path)
        .then((res) => {
          this.state.data = res.data.content
        })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>